import React, { useState, useEffect } from 'react';
import { Share2, Users, X, LogIn, LogOut } from 'lucide-react';
import { realtimeService } from '../services/RealtimeService';

export const CollaborationPanel = ({ onCreateRoom, onJoinRoom, onLeaveRoom, roomId }) => {
  const [joinRoomId, setJoinRoomId] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [usersCount, setUsersCount] = useState(1);

  useEffect(() => {
    if (roomId) {
      setIsExpanded(true);
    } else {
      setUsersCount(1);
    }

    const handleUsersUpdate = (count) => {
      setUsersCount(count);
    };

    realtimeService.onUsersUpdate(handleUsersUpdate);

    return () => {
      realtimeService.offUsersUpdate(handleUsersUpdate);
    };
  }, [roomId]);

  const handleCreateRoom = async () => {
    const newRoomId = await realtimeService.createRoom();
    onCreateRoom(newRoomId);
  };

  const handleJoinRoom = () => {
    if (joinRoomId) {
      onJoinRoom(joinRoomId);
    }
  };

  const handleLeaveRoom = () => {
    onLeaveRoom();
    setUsersCount(1);
  };

  const getCollaboratorsText = () => {
    if (!roomId) {
      return "Not in a room";
    } else if (usersCount === 1) {
      return "You're the only one here";
    } else {
      return `${usersCount} collaborators online`;
    }
  };

  return (
    <div className={`collaboration-panel fixed top-4 right-4 bg-white rounded-lg shadow-md transition-all duration-300 ease-in-out z-10 ${isExpanded ? 'w-80 p-6' : 'w-12 h-12'}`}>
      {isExpanded ? (
        <>
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
          <h2 className="text-2xl font-semibold mb-6">Collaboration</h2>
          {roomId ? (
            <div className="space-y-4">
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Current Room</h3>
                <p className="bg-gray-100 p-2 rounded-lg text-center font-mono">{roomId}</p>
              </div>
              <button onClick={handleLeaveRoom} className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105">
                <LogOut className="w-5 h-5 mr-2" />
                Leave Room
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <button onClick={handleCreateRoom} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105">
                <Share2 className="w-5 h-5 mr-2" />
                Create Room
              </button>
              <div className="flex flex-col space-y-2">
                <input
                  type="text"
                  placeholder="Room ID"
                  value={joinRoomId}
                  onChange={(e) => setJoinRoomId(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button onClick={handleJoinRoom} className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-center transition duration-300 ease-in-out transform hover:scale-105">
                  <LogIn className="w-5 h-5 mr-2" />
                  Join Room
                </button>
              </div>
            </div>
          )}
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Collaborators</h3>
            <div className="flex items-center space-x-2 mb-2">
              <Users className="w-5 h-5 text-gray-600" />
              <span className="text-gray-600">{getCollaboratorsText()}</span>
            </div>
          </div>
        </>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className="w-full h-full flex items-center justify-center bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out"
        >
          <Users size={24} />
        </button>
      )}
    </div>
  );
};