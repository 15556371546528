class RealtimeService {
  constructor() {
    this.socket = null;
    this.roomId = null;
    this.canvasVersion = 0;
    this.eventListeners = {};
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
  }

  connect() {
    return new Promise((resolve, reject) => {
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const host = window.location.host;
      const url = `${protocol}//${host}/api/socketio`;
      
      console.log(`Attempting to connect to WebSocket at: ${url}`);

      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        console.log('WebSocket connection established successfully');
        this.reconnectAttempts = 0;
        resolve();
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket connection error:', error);
        this.attemptReconnect(reject);
      };

      this.socket.onclose = (event) => {
        console.log(`WebSocket connection closed. Code: ${event.code}, Reason: ${event.reason}`);
        this.attemptReconnect(reject);
      };

      this.socket.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          if (this.eventListeners[message.event]) {
            this.eventListeners[message.event].forEach(callback => callback(message));
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };
    });
  }

  attemptReconnect(reject) {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
      setTimeout(() => this.connect(), 2000);
    } else {
      console.error('Max reconnection attempts reached. Giving up.');
      reject(new Error('Failed to establish WebSocket connection after multiple attempts'));
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
    }
  }

  on(event, callback) {
    if (!this.eventListeners[event]) {
      this.eventListeners[event] = [];
    }
    this.eventListeners[event].push(callback);
  }

  off(event, callback) {
    if (this.eventListeners[event]) {
      this.eventListeners[event] = this.eventListeners[event].filter(cb => cb !== callback);
    }
  }

  emit(event, data) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({ event, ...data });
      console.log(`Sending message: ${message}`);
      this.socket.send(message);
    } else {
      console.error('Cannot send message. WebSocket is not open.');
    }
  }
  
  joinRoom(roomId) {
    this.roomId = roomId;
    this.emit('join-room', { roomId });
  }

  leaveRoom() {
    if (this.roomId) {
      this.emit('leave-room', { roomId: this.roomId });
      this.roomId = null;
      this.canvasVersion = 0;
    }
  }

  sendCanvasUpdate(canvasState) {
    if (this.roomId) {
      this.canvasVersion++;
      this.emit('canvas-update', {
        roomId: this.roomId,
        version: this.canvasVersion,
        state: canvasState
      });
    }
  }

  requestLatestCanvas() {
    if (this.roomId) {
      this.emit('request-canvas', {
        roomId: this.roomId,
        currentVersion: this.canvasVersion
      });
    }
  }

  createRoom() {
    return new Promise((resolve) => {
      this.emit('create-room');
      this.on('room-created', (data) => {
        this.roomId = data.roomId;
        resolve(data.roomId);
      });
    });
  }

  onCanvasUpdate(callback) {
    this.on('canvas-update', (data) => {
      if (data.version > this.canvasVersion) {
        this.canvasVersion = data.version;
        callback(data.state);
      }
    });
  }

  onUsersUpdate(callback) {
    this.on('users-update', callback);
  }

  onInitialCanvasState(callback) {
    this.on('initial-canvas-state', (data) => {
      this.canvasVersion = data.version;
      callback(data.state);
    });
  }

  onRoomNotFound(callback) {
    this.on('room-not-found', callback);
  }
}

export const realtimeService = new RealtimeService();