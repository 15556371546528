import React, { useState, useEffect, useMemo } from 'react';
import { 
  MousePointer, 
  Square, 
  Circle, 
  ArrowRight, 
  Pen, 
  Type, 
  Image, 
  Eraser 
} from 'lucide-react';

export const Toolbar = ({ onSelectTool, selectedTool, onEraserSizeChange }) => {
  const [eraserSize, setEraserSize] = useState(20);
  
  const tools = useMemo(() => [
    { name: 'select', icon: MousePointer, key: '1' },
    { name: 'rectangle', icon: Square, key: '2' },
    { name: 'circle', icon: Circle, key: '3' },
    { name: 'arrow', icon: ArrowRight, key: '4' },
    { name: 'pen', icon: Pen, key: '5' },
    { name: 'text', icon: Type, key: '6' },
    { name: 'image', icon: Image, key: '7' },
    { name: 'eraser', icon: Eraser, key: '8' },
  ], []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const tool = tools.find(t => t.key === e.key);
      if (tool) {
        onSelectTool(tool.name);
      }
    };
 
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onSelectTool, tools]);

  const handleToolClick = (e, toolName) => {
    e.stopPropagation();
    onSelectTool(toolName);
  };

  const handleEraserSizeChange = (e) => {
    const size = parseInt(e.target.value);
    setEraserSize(size);
    onEraserSizeChange(size);
  };

  return (
    <div className="toolbar fixed top-4 left-1/2 transform -translate-x-1/2 bg-gray-800 rounded-lg shadow-md p-2 flex flex-col items-center space-y-2 z-10">
      <div className="flex space-x-2">
        {tools.map((tool) => (
          <button
            key={tool.name}
            onClick={(e) => handleToolClick(e, tool.name)}
            className={`w-10 h-10 flex items-center justify-center rounded-md ${
              selectedTool === tool.name ? 'bg-blue-500' : 'bg-gray-800 hover:bg-gray-700'
            } text-white relative`}
          >
            <tool.icon className="w-6 h-6" />
            <span className="absolute bottom-0 right-0 text-xs bg-gray-700 rounded-full w-4 h-4 flex items-center justify-center">
              {tool.key}
            </span>
          </button>
        ))}
      </div>
      {selectedTool === 'eraser' && (
        <div className="flex flex-col items-center space-y-2 w-full">
          <div className="flex items-center justify-between w-full">
            <span className="text-sm text-white">Eraser Size:</span>
            <span className="text-sm text-white">{eraserSize}px</span>
          </div>
          <input
            type="range"
            min="5"
            max="50"
            value={eraserSize}
            onChange={handleEraserSizeChange}
            className="w-full"
          />
          <div className="w-full h-10 bg-white rounded-md flex items-center justify-center">
            <div
              className="bg-gray-800 rounded-full"
              style={{ width: `${eraserSize}px`, height: `${eraserSize}px` }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};
