import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Stage, Layer, Line, Rect, Circle, Arrow, Text, Transformer, Image } from 'react-konva';
import { Toolbar } from './components/Toolbar';
import { ColorPicker } from './components/ColorPicker';
import { CollaborationPanel } from './components/CollaborationPanel';
import { ContextMenu } from './components/ContextMenu';
import { realtimeService } from './services/RealtimeService';
import { Loader2 } from 'lucide-react';
import debounce from 'lodash/debounce';
import useHistory from './hooks/useHistory';

const App = () => {
  const [tool, setTool] = useState('select');
  const { elements, setElements, undo, redo } = useHistory([]);
  const [selectedColor, setSelectedColor] = useState('#000000');
  const [isDrawing, setIsDrawing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [textPosition, setTextPosition] = useState(null);
  const [selectedId, selectShape] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [eraserSize, setEraserSize] = useState(20);
  const [connectionStatus, setConnectionStatus] = useState('connecting');
  const stageRef = useRef(null);
  const layerRef = useRef(null);

  const serializeElements = useCallback((elements) => {
    return elements.map(el => {
      if (el.type === 'image') {
        return { ...el, image: el.image.src };
      }
      return el;
    });
  }, []);

  const deserializeElements = useCallback((elements) => {
    return elements.map(el => {
      if (el.type === 'image') {
        const img = new window.Image();
        img.src = el.image;
        return { ...el, image: img };
      }
      return el;
    });
  }, []);

  const debouncedSendUpdate = useMemo(
    () => debounce((canvasState) => {
      realtimeService.sendCanvasUpdate(serializeElements(canvasState));
    }, 100),
    [serializeElements]
  );

  useEffect(() => {
    const connectToServer = async () => {
      try {
        await realtimeService.connect();
        setConnectionStatus('connected');
        setIsLoading(false);
      } catch (err) {
        console.error('Connection error:', err);
        setConnectionStatus('error');
        setError('Failed to connect to the server. Please check your internet connection and try again.');
        setIsLoading(false);
      }
    };

    connectToServer();

    realtimeService.on('canvas-update', (data) => {
      setElements(deserializeElements(data.state));
    });

    realtimeService.on('initial-canvas-state', (data) => {
      setElements(deserializeElements(data.state));
    });

    realtimeService.on('room-not-found', () => {
      setError('Room not found. Please check the room ID and try again.');
      setRoomId(null);
    });

    realtimeService.on('users-update', (data) => {
      console.log(`Users in room: ${data.count}`);
      // You can update UI to show the number of users if needed
    });

    const syncInterval = setInterval(() => {
      if (roomId && connectionStatus === 'connected') {
        realtimeService.requestLatestCanvas();
      }
    }, 1000);

    return () => {
      realtimeService.disconnect();
      clearInterval(syncInterval);
      debouncedSendUpdate.cancel();
    };
  }, [roomId, deserializeElements, setElements, debouncedSendUpdate, connectionStatus]);

  const handleStageMouseDown = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      setContextMenu(null);
    }

    const pos = e.target.getStage().getPointerPosition();

    if (tool === 'pen' || tool === 'eraser') {
      setIsDrawing(true);
      const newLine = {
        id: Date.now(),
        type: tool,
        points: [pos.x, pos.y],
        color: tool === 'eraser' ? 'white' : selectedColor,
        strokeWidth: tool === 'eraser' ? eraserSize : 5,
      };
      setElements((prevElements) => [...prevElements, newLine]);
    } else if (['rectangle', 'circle', 'arrow'].includes(tool)) {
      const newShape = {
        id: Date.now(),
        type: tool,
        x: pos.x,
        y: pos.y,
        width: 0,
        height: 0,
        fill: selectedColor,
      };
      setElements((prevElements) => [...prevElements, newShape]);
      selectShape(`${tool}-${newShape.id}`);
      setIsDrawing(true);
    } else if (tool === 'text') {
      setTextPosition(pos);
    } else if (tool === 'image') {
      handleImageUpload(pos);
    }
  };

  const handleStageMouseMove = (e) => {
    if (!isDrawing) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    
    setElements((prevElements) => {
      const lastElement = prevElements[prevElements.length - 1];
      if (lastElement.type === 'pen' || lastElement.type === 'eraser') {
        const newPoints = lastElement.points.concat([point.x, point.y]);
        const updatedElement = { ...lastElement, points: newPoints };
        return [...prevElements.slice(0, -1), updatedElement];
      } else if (['rectangle', 'circle', 'arrow'].includes(lastElement.type)) {
        const updatedElement = {
          ...lastElement,
          width: point.x - lastElement.x,
          height: point.y - lastElement.y,
        };
        return [...prevElements.slice(0, -1), updatedElement];
      }
      return prevElements;
    });
  };

  const handleStageMouseUp = () => {
    if (isDrawing) {
      setIsDrawing(false);
      debouncedSendUpdate(elements);
    }
  };

  const handleShapeDragEnd = (e, id) => {
    const updatedElements = elements.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          x: e.target.x(),
          y: e.target.y(),
        };
      }
      return el;
    });
    setElements(updatedElements);
    debouncedSendUpdate(updatedElements);
  };

  const handleShapeTransform = (newAttrs, id) => {
    const updatedElements = elements.map((el) => {
      if (el.id === id) {
        if (el.type === 'arrow') {
          const node = layerRef.current.findOne(`#${el.type}-${el.id}`);
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          
          node.scaleX(1);
          node.scaleY(1);

          return {
            ...el,
            x: node.x(),
            y: node.y(),
            width: el.width * scaleX,
            height: el.height * scaleY,
            points: [0, 0, el.width * scaleX, el.height * scaleY],
          };
        }
        return {
          ...el,
          ...newAttrs,
        };
      }
      return el;
    });
    setElements(updatedElements);
    debouncedSendUpdate(updatedElements);
  };

  const handleClearCanvas = () => {
    setElements([]);
    realtimeService.sendCanvasUpdate([]);
  };

  const handleTextInput = (e) => {
    setTextInput(e.target.value);
  };

  const handleTextSubmit = () => {
    if (textInput && textPosition) {
      const newText = {
        id: Date.now(),
        type: 'text',
        x: textPosition.x,
        y: textPosition.y,
        text: textInput,
        fill: selectedColor,
        fontSize: 16,
      };
      const updatedElements = [...elements, newText];
      setElements(updatedElements);
      debouncedSendUpdate(updatedElements);
      setTextInput('');
      setTextPosition(null);
    }
  };

  const handleImageUpload = (pos) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new window.Image();
        img.src = event.target.result;
        img.onload = () => {
          const newImage = {
            id: Date.now(),
            type: 'image',
            x: pos.x,
            y: pos.y,
            width: 100,
            height: 100 * (img.height / img.width),
            image: img,
          };
          const updatedElements = [...elements, newImage];
          setElements(updatedElements);
          debouncedSendUpdate(updatedElements);
        };
      };
      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleContextMenu = (e) => {
    e.evt.preventDefault();
    if (e.target === e.target.getStage()) {
      setContextMenu(null);
      return;
    }
    const clickedOnShape = e.target.getAttr('id') !== undefined;
    if (clickedOnShape) {
      selectShape(e.target.getAttr('id'));
      setContextMenu({
        x: e.evt.clientX,
        y: e.evt.clientY,
      });
    }
  };

  const handleLayerChange = (direction) => {
    const selectedIndex = elements.findIndex((el) => `${el.type}-${el.id}` === selectedId);
    if (selectedIndex === -1) return;

    const newElements = [...elements];
    const [selected] = newElements.splice(selectedIndex, 1);

    if (direction === 'up' && selectedIndex < elements.length - 1) {
      newElements.splice(selectedIndex + 1, 0, selected);
    } else if (direction === 'down' && selectedIndex > 0) {
      newElements.splice(selectedIndex - 1, 0, selected);
    } else if (direction === 'top') {
      newElements.push(selected);
    } else if (direction === 'bottom') {
      newElements.unshift(selected);
    }

    setElements(newElements);
    debouncedSendUpdate(newElements);
  };

  const handleCreateRoom = async () => {
    try {
      const newRoomId = await realtimeService.createRoom();
      setRoomId(newRoomId);
      realtimeService.sendCanvasUpdate(serializeElements(elements));
    } catch (error) {
      console.error('Error creating room:', error);
      setError('Failed to create room. Please try again.');
    }
  };

  const handleJoinRoom = (joinRoomId) => {
    try {
      setRoomId(joinRoomId);
      realtimeService.joinRoom(joinRoomId);
    } catch (error) {
      console.error('Error joining room:', error);
      setError('Failed to join room. Please check the room ID and try again.');
    }
  };

  const handleLeaveRoom = () => {
    try {
      realtimeService.leaveRoom();
      setRoomId(null);
    } catch (error) {
      console.error('Error leaving room:', error);
      setError('Failed to leave room. Please try again.');
    }
  };

  const handleShapeClick = (shapeId) => {
    selectShape(shapeId);
    const selectedElement = elements.find((el) => `${el.type}-${el.id}` === shapeId);
    if (selectedElement && selectedElement.fill) {
      setSelectedColor(selectedElement.fill);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    if (selectedId) {
      const updatedElements = elements.map((el) => {
        if (`${el.type}-${el.id}` === selectedId) {
          return { ...el, fill: color };
        }
        return el;
      });
      setElements(updatedElements);
      debouncedSendUpdate(updatedElements);
    }
  };

  const handleEraserSizeChange = (size) => {
    setEraserSize(size);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
        <span className="ml-2">Connecting to server...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <Toolbar 
        onSelectTool={setTool} 
        selectedTool={tool} 
        onEraserSizeChange={handleEraserSizeChange}
        onUndo={undo}
        onRedo={redo}
      />
      <ColorPicker onSelectColor={handleColorChange} selectedColor={selectedColor} />
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseDown={handleStageMouseDown}
        onMousemove={handleStageMouseMove}
        onMouseup={handleStageMouseUp}
        onContextMenu={handleContextMenu}
        ref={stageRef}
      >
        <Layer ref={layerRef}>
          {elements.map((element, i) => {
            const shapeProps = {
              key: element.id,
              id: `${element.type}-${element.id}`,
              draggable: tool === 'select',
              onDragEnd: (e) => handleShapeDragEnd(e, element.id),
              onClick: () => handleShapeClick(`${element.type}-${element.id}`),
              onTap: () => handleShapeClick(`${element.type}-${element.id}`),
            };

            if (element.type === 'pen' || element.type === 'eraser') {
              return (
                <Line
                  key={element.id}
                  points={element.points}
                  stroke={element.color}
                  strokeWidth={element.strokeWidth}
                  tension={0.5}
                  lineCap="round"
                  globalCompositeOperation={
                    element.type === 'eraser' ? 'destination-out' : 'source-over'
                  }
                />
              );
            } else if (element.type === 'rectangle') {
              return (
                <Rect
                  {...shapeProps}
                  x={element.x}
                  y={element.y}
                  width={element.width}
                  height={element.height}
                  fill={element.fill}
                />
              );
            } else if (element.type === 'circle') {
              return (
                  <Circle
                      {...shapeProps}
                      x={element.x}
                      y={element.y}
                      radius={Math.abs(element.width / 2)}
                      fill={element.fill}
                      onDragMove={(e) => {
                          const updatedElements = elements.map((el) => {
                              if (el.id === element.id) {
                                  return {
                                      ...el,
                                      x: e.target.x(),
                                      y: e.target.y(),
                                  };
                              }
                              return el;
                          });
                          setElements(updatedElements);
                      }}
                      onTransformEnd={(e) => {
                          const node = e.target;
                          const scaleX = node.scaleX();
                          const scaleY = node.scaleY();
                          node.scaleX(1);
                          node.scaleY(1);
                          const updatedElements = elements.map((el) => {
                              if (el.id === element.id) {
                                  return {
                                      ...el,
                                      x: node.x(),
                                      y: node.y(),
                                      radius: Math.max(node.width() / 2 * scaleX, node.height() / 2 * scaleY),
                                  };
                              }
                              return el;
                          });
                          setElements(updatedElements);
                          realtimeService.sendUpdate({ type: 'elementUpdate', data: updatedElements });
                      }}
                  />
              );
          } else if (element.type === 'arrow') {
              return (
                <Arrow
                  {...shapeProps}
                  points={[0, 0, element.width, element.height]}
                  x={element.x}
                  y={element.y}
                  pointerLength={20}
                  pointerWidth={20}
                  fill={element.fill}
                  stroke={element.fill}
                />
              );
            } else if (element.type === 'text') {
              return (
                <Text
                  {...shapeProps}
                  x={element.x}
                  y={element.y}
                  text={element.text}
                  fontSize={element.fontSize}
                  fill={element.fill}
                />
              );
            } else if (element.type === 'image') {
              return (
                <Image
                  key={element.id}
                  id={`${element.type}-${element.id}`}
                  x={element.x}
                  y={element.y}
                  width={element.width}
                  height={element.height}
                  image={element.image}
                  draggable={tool === 'select'}
                  onDragEnd={(e) => handleShapeDragEnd(e, element.id)}
                  onClick={() => handleShapeClick(`${element.type}-${element.id}`)}
                  onTap={() => handleShapeClick(`${element.type}-${element.id}`)}
                />
              );
            }
            return null;
          })}
          {selectedId && (
            <Transformer
              nodes={[layerRef.current?.findOne(`#${selectedId}`)].filter(Boolean)}
              keepRatio={false}
              boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
              onTransform={() => {
                const node = layerRef.current?.findOne(`#${selectedId}`);
                if (!node) return;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
                node.scaleX(1);
                node.scaleY(1);
                const id = parseInt(selectedId.split('-')[1]);
                const element = elements.find(el => el.id === id);
                if (element) {
                  const newAttrs = {
                    x: node.x(),
                    y: node.y(),
                    width: node.width() * scaleX,
                    height: node.height() * scaleY,
                  };
                  if (element.type === 'circle') {
                    newAttrs.radius = Math.abs(newAttrs.width / 2);
                  } else if (element.type === 'text') {
                    newAttrs.fontSize = element.fontSize * scaleY;
                  } else if (element.type === 'arrow') {
                    newAttrs.points = [0, 0, newAttrs.width, newAttrs.height];
                  }
                  handleShapeTransform(newAttrs, id);
                }
              }}
            />
          )}
        </Layer>
      </Stage>
      <CollaborationPanel 
        onCreateRoom={handleCreateRoom}
        onJoinRoom={handleJoinRoom}
        onLeaveRoom={handleLeaveRoom}
        roomId={roomId}
      />
      <button
        className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleClearCanvas}
      >
        Clear Canvas
      </button>
      {textPosition && (
        <div className="fixed top-4 left-4 bg-white rounded-lg shadow-md p-2">
          <input
            type="text"
            value={textInput}
            onChange={handleTextInput}
            className="border rounded px-2 py-1"
            placeholder="Enter text"
          />
          <button
            onClick={handleTextSubmit}
            className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          >
            Add Text
          </button>
          <button
            onClick={() => setTextPosition(null)}
            className="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
          >
            Cancel
          </button>
        </div>
      )}
      {contextMenu && (
        <ContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          onClose={() => setContextMenu(null)}
          onLayerChange={handleLayerChange}
        />
      )}
    </div>
  );
};

export default App;