import React from 'react';

export const ContextMenu = ({ x, y, onClose, onLayerChange }) => {
  return (
    <div
      className="absolute bg-white border border-gray-300 rounded shadow-lg py-2"
      style={{ top: y, left: x }}
    >
      <button
        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
        onClick={() => {
          onLayerChange('up');
          onClose();
        }}
      >
        Bring Forward
      </button>
      <button
        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
        onClick={() => {
          onLayerChange('down');
          onClose();
        }}
      >
        Send Backward
      </button>
      <button
        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
        onClick={() => {
          onLayerChange('top');
          onClose();
        }}
      >
        Bring to Front
      </button>
      <button
        className="block w-full text-left px-4 py-2 hover:bg-gray-100"
        onClick={() => {
          onLayerChange('bottom');
          onClose();
        }}
      >
        Send to Back
      </button>
    </div>
  );
};