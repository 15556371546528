import { useState, useCallback } from 'react';

const useHistory = (initialState) => {
  const [elements, setElements] = useState(initialState);
  const [history, setHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);

  const pushToHistory = useCallback((newElements) => {
    setHistory((prevHistory) => [...prevHistory, elements]);
    setRedoHistory([]);
    setElements(newElements);
  }, [elements]);

  const undo = useCallback(() => {
    if (history.length > 0) {
      const prevState = history[history.length - 1];
      setRedoHistory((prevRedoHistory) => [...prevRedoHistory, elements]);
      setHistory((prevHistory) => prevHistory.slice(0, -1));
      setElements(prevState);
    }
  }, [history, elements]);

  const redo = useCallback(() => {
    if (redoHistory.length > 0) {
      const nextState = redoHistory[redoHistory.length - 1];
      setHistory((prevHistory) => [...prevHistory, elements]);
      setRedoHistory((prevRedoHistory) => prevRedoHistory.slice(0, -1));
      setElements(nextState);
    }
  }, [redoHistory, elements]);

  return { elements, setElements: pushToHistory, undo, redo };
};

export default useHistory;