import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

export const ColorPicker = ({ onSelectColor, selectedColor }) => {
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const colors = [
    '#000000', '#FFFFFF', '#FF0000', '#00FF00', '#0000FF', 
    '#FFFF00', '#00FFFF', '#FF00FF', '#C0C0C0', '#808080'
  ];

  const handleColorClick = (e, color) => {
    e.stopPropagation();
    onSelectColor(color);
  };

  const handleCustomColorChange = (color) => {
    onSelectColor(color.hex);
  };

  return (
    <div className="color-picker fixed top-20 left-4 bg-white rounded-lg shadow-md p-2 flex flex-col space-y-2 z-10">
      {colors.map((color) => (
        <button
          key={color}
          onClick={(e) => handleColorClick(e, color)}
          className={`w-8 h-8 rounded-full ${selectedColor === color ? 'ring-2 ring-offset-2 ring-blue-500' : ''} ${color === '#FFFFFF' ? 'border border-gray-300' : ''}`}
          style={{ backgroundColor: color }}
        />
      ))}
      <button
        onClick={() => setShowCustomPicker(!showCustomPicker)}
        className="w-8 h-8 rounded-full bg-gradient-to-r from-red-500 via-green-500 to-blue-500"
      />
      {showCustomPicker && (
        <div className="absolute left-full ml-2">
          <SketchPicker
            color={selectedColor}
            onChange={handleCustomColorChange}
          />
        </div>
      )}
    </div>
  );
};